<template>
    <el-dialog
        :title="$t('economy.edit_invoice')"
        :visible.sync="modalEditInvoice"
        :before-close="closeModal"
        :append-to-body="true"
        width="1200px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <el-form ref="formData" :model="formData" label-position="top" :rules="rules" class="px-16 grid grid-cols-2 gap-6">
            <div class="">
                <p class="text-center mb-3">
                    {{ $t('economy.invoice_data') }}
                </p>
                <div class="p-6 rounded-lg bg-purple-100 border border-purple-200">
                    <el-form-item :label="$t('economy.issue_at')" prop="issue_at" class="col">
                        <el-date-picker
                            v-model="formData.issue_at"
                            type="date"
                            :clearable="false"
                            :editable="false"
                            :picker-options="{ firstDayOfWeek: 1 }"
                            :placeholder="$t('economy.issue_at')"
                            format="dd.MM.yyyy"
                            value-format="dd.MM.yyyy"
                            @change="issueAtChanged"
                        />
                    </el-form-item>
                    <el-form-item :label="$t('economy.due_date')" prop="due_date_at" class="col w-full">
                        <el-date-picker
                            v-model="formData.due_date_at"
                            type="date"
                            :clearable="false"
                            :editable="false"
                            class="w-full"
                            :picker-options="{ firstDayOfWeek: 1 }"
                            :placeholder="$t('economy.due_date')"
                            format="dd.MM.yyyy"
                            value-format="dd.MM.yyyy"
                            @change="dueDateAtChanged"
                        />
                    </el-form-item>
                </div>
            </div>
            <div class="">
                <p class="text-center mb-3">
                    {{ $t('economy.client_data') }}
                </p>
                <div class="p-6 rounded-lg bg-green-100 border border-green-200">
                    <el-form-item :label="$t('economy.name')">
                        <el-input v-model="formData.client_data.name" />
                    </el-form-item>
                    <el-form-item :label="$t('economy.surname')">
                        <el-input v-model="formData.client_data.surname" :disabled="formData.client_data.type === 'company'" />
                    </el-form-item>
                    <el-form-item :label="$t('economy.client_nr')">
                        <el-input v-model="formData.client_data.custom_id" />
                    </el-form-item>
                    <el-form-item :label="$t('economy.email')">
                        <el-input v-model="formData.client_data.email" />
                    </el-form-item>
                    <el-form-item :label="$t('economy.street')">
                        <el-input v-model="formData.client_data.street" />
                    </el-form-item>
                    <el-form-item :label="$t('economy.post_code')">
                        <el-input v-model="formData.client_data.post_code" />
                    </el-form-item>
                    <el-form-item :label="$t('economy.city')">
                        <el-input v-model="formData.client_data.city" />
                    </el-form-item>
                    <el-form-item :label="formData.client_data.type === 'company' ? 'Orgnummer' : 'Personummer'">
                        <el-input v-model="formData.client_data.person_org_nummer" />
                    </el-form-item>
                    <el-form-item :label="$t('economy.autogiro_id')">
                        <el-input v-model="formData.client_data.autogiro_id" />
                    </el-form-item>
                    <!-- type, -->
                    <!-- has_rut, -->
                </div>
            </div>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button :disabled="$wait.is('updating')" @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('updating')" @click="updateInvoice">{{ $t('common.update') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import verifyDueDate from './Validator';

export default {
    props: {
        modalEditInvoice: {
            type:    Boolean,
            default: false,
        },
        selectedInvoiceProp: {
            type: Object,
            default() {
                return {
                    selectedInvoiceProp: {},
                };
            },
        },
    },

    data() {
        const dueDateValidator = (rule, value, callback) => verifyDueDate(
            value,
            this.$dayjs(this.formData.issue_at, 'DD.MM.YYYY'),
            this.$dayjs(this.formData.due_date_at, 'DD.MM.YYYY'),
            callback,
            this,
        );

        return {
            formData: {
                // issueAt: this.$dayjs(),
                // dueDateAt: this.$dayjs().add(14, 'days'),
                ...({ ...this.selectedInvoiceProp }),
            },
            rules: {
                issue_at:    [{ validator: dueDateValidator, trigger: 'blur' }],
                due_date_at: [{ validator: dueDateValidator, trigger: 'blur' }],
            },
        };
    },

    created() {
        // this.getDetails();
        console.log(
            this.formData,
        );
    },

    methods: {
        async getDetails() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/billing_information`);
            this.formData = data;
        },

        async updateInvoice() {
            await new Promise(resolve => this.$refs.formData.validate(valid => valid && resolve()));

            this.$wait.start('updating');

            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/${this.formData.uuid}`, {
                issue_at:    this.$dayjs(this.formData.issue_at, 'DD.MM.YYYY').unix(),
                due_date_at: this.$dayjs(this.formData.due_date_at, 'DD.MM.YYYY').unix(),

                custom_id:         this.formData.client_data.custom_id,
                email:             this.formData.client_data.email,
                name:              this.formData.client_data.name,
                surname:           this.formData.client_data.surname,
                street:            this.formData.client_data.street,
                post_code:         this.formData.client_data.post_code,
                city:              this.formData.client_data.city,
                person_org_nummer: this.formData.client_data.person_org_nummer,
                autogiro_id:       this.formData.client_data.autogiro_id,
            });

            this.$wait.end('updating');
            this.$emit('refresh');
            this.$emit('close');
        },

        closeModal() {
            axios.cancelAll();
            this.$emit('close');
        },
    },
};
</script>
<style>
.el-transfer-panel {
    margin-top: 30px;
    width: 240px;
}
</style>
